import {FatalError} from '../../../components/FatalError/FatalError';

export const ru = {
  common: {
    order: 'Заказ: {{order}}',
    h1: {
      sbp: 'JPAY СБП',
      card: 'JPAY P2P',
      account: 'JPAY',
      accountUnder: 'ПО НОМЕРУ СЧЁТА',
    },
    title: {
      sbp: 'Перевод по номеру телефона',
      card: 'Перевод по номеру карты',
      account: 'Перевод по номеру счёта',
    },
  },
  BankList: {
    returnToShop: 'Вернуться на сайт',
    selectBank: 'Выберите банк, с которого будете переводить средства',
    bankSelectionNotice: 'Будьте аккуратны, повторно выбрать банк нельзя',
    nextStep: 'Продолжить',
  },
  AmountChanged: {
    amountChangedTitle: 'К сожалению, реквизиты на изначальную сумму временно недоступны.',
    amountChangedSubTitle1: 'Предлагаем Вам оплатить:',
    amountChangedSubTitle2: 'Предлагаемая сумма будет начислена на Ваш баланс',
    amountChangedConfirm: 'Продолжить',
    cancel: 'Отменить',
  },
  CardForm: {
    pan: 'Номер карты:',
    phone: 'Номер телефона:',
    account: 'Номер счёта:',
    recipient: 'Получатель:',
    paymentConfirmed: 'Я оплатил',
    cancel: 'Отменить',
    HowToJPAY: {
      title: 'Инструкция:',
      card: {
        1: 'Скопируйте номер карты',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи - Переводы - В другую страну - Армения - по номеру карты',
        4: 'Вставьте номер карты и имя получателя ЛАТИНСКИМИ БУКВАМИ',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
      sbp: {
        1: 'Скопируйте номер телефона',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи - Переводы - В другую страну - Армения - по номеру телефона',
        4: 'Вставьте номер телефона и имя получателя ЛАТИНСКИМИ БУКВАМИ (Если Требуется)',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
      account: {
        1: 'Скопируйте номер телефона',
        2: 'Откройте приложение своего банка',
        3: 'Откройте Платежи - Переводы - по номеру телефона',
        4: 'Вставьте номер телефона',
        5: 'Переведите строго указанную сумму без учёта комиссии',
      },
    }
  },
  StatusBlock: {
    paymentSuccessful: 'Перевод получен!',
    paymentCanceled: 'Вы отменили оплату',
    paymentDeclined: 'Произошла какая-то ошибка... Ваш перевод не найден.',
    paymentPaid: 'Ожидаем поступление средств...',
    paymentTimeout: 'Время оплаты истекло...',
    support: 'Служба Поддержки',
    order: 'Ваш заказ: {{order}}',
  },
  FatalError: {
    support: 'Служба Поддержки',
    returnToShop: 'Вернуться на сайт',
  }
}
