import alfa from '../asssets/icons/alfa.svg'
import sber from '../asssets/icons/sber.svg'
import vtb from '../asssets/icons/vtb.svg'
import tinkoff from '../asssets/icons/tinkoff.svg'
import pochtabank from '../asssets/icons/pochtabank.svg'
import uralsib from '../asssets/icons/uralsib.png'
import gazprom from '../asssets/icons/gazprom.png'
import promsvyazbank from '../asssets/icons/promsvyazbank.png'
import rshb from '../asssets/icons/rshb.png'
import forabank from '../asssets/icons/forabank.png'
import yoomoney from '../asssets/icons/yoomoney.png'
import other from '../asssets/icons/other.png'

export const sourceBanksImageMap = {
  alfa,
  sber,
  vtb,
  tinkoff,
  pochtabank,
  uralsib,
  gazprom,
  promsvyazbank,
  rshb,
  forabank,
  yoomoney,
  other,
}

export const sourceBanks = {
  sber: {
    name_en: "Sber",
    name_ru: "Сбербанк",
    key: "sber",
    img: 'sber',
    amount_min: "10000.0000",
  },
  tinkoff: {
    name_en: "T-Bank",
    name_ru: "Т-Банк",
    key: "tinkoff",
    img: 'tinkoff',
  },
  vtb: {
    name_en: "VTB",
    name_ru: "ВТБ",
    key: "vtb",
    img: 'vtb',
  },
  alfa: {
    name_en: "Alfa Bank",
    name_ru: "Альфа Банк",
    key: "alfa",
    img: 'alfa',
  },
  pochtabank: {
    name_en: 'Pochta Bank',
    name_ru: 'Почта Банк',
    key: 'pochtabank',
    img: 'pochtabank',
  },
  uralsib: {
    name_en: 'URALSIB',
    name_ru: 'УРАЛСИБ',
    key: 'uralsib',
    img: 'uralsib',
  },
  gazprom: {
    name_en: 'Gazprom Bank',
    name_ru: 'Газпром Банк',
    key: 'gazprom',
    img: 'gazprom',
  },
  promsvyazbank: {
    name_en: 'Promsvyaz Bank',
    name_ru: 'Промсвязь Банк',
    key: 'promsvyazbank',
    img: 'promsvyazbank',
  },
  rshb: {
    name_en: 'RSHB',
    name_ru: 'РСХБ',
    key: 'rshb',
    img: 'rshb',
  },
  forabank: {
    name_en: 'Fora Bank',
    name_ru: 'Фора Банк',
    key: 'forabank',
    img: 'forabank',
  },
  yoomoney: {
    name_en: 'YooMoney',
    name_ru: 'ЮMoney',
    key: 'yoomoney',
    img: 'yoomoney',
  },
  other: {
    name_en: 'Other bank',
    name_ru: 'Другой банк',
    key: 'other',
    img: 'other',
  },
}
