import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl, InputLabel, MenuItem, Select,
  Tooltip,
  Typography, useMediaQuery, useTheme
} from '@mui/material'
import {useSelector} from 'react-redux'
import {getBankInfo} from '../../../../config/bankInfo'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import {dispatch} from '../../../../store/store'
import {formSlice} from '../../../../store/form/formSlice'
import {getRequisiteType} from '../../../../config/paymentTypes'
import {apiURL} from '../../../../config/constants'
import {openReplay} from '../../../../externals/openreplay'
import './cardForm.css'

import {getMobileOperatingSystem} from '../../../../utils/getMobileOperatingSystem'
import {useTranslation} from "react-i18next"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import {nowToList} from "../../../../config/nowToList"
import {Layout} from '../Layout/Layout'
import {redirect} from '../../../../utils/redirect'
import {RequisitesCard} from '../Requisites/RequisitesCard'
import styles from '../base.module.scss'
import {Amount} from '../Amount/Amount';
import {stylesObject} from '../stylesObject';

async function upload({invoice_id, file}) {
  const formData = new FormData()
  formData.append("file", file.file)
  formData.append("invoice_id", invoice_id)

  try {
    const response = await fetch(`${apiURL}/p2p-selector/screenshot`, {
      method: 'POST',
      body: formData
    })
    const json = await response.json()
    if (json.status === 'success') {
      return {status: 'success'}
    }
    return {status: 'error'}
  } catch (e) {
    return {status: 'error'}
  }
}

export function CardForm() {
  const theme = useTheme()
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'CardForm'})
  const {t: ct} = useTranslation('translation', {keyPrefix: 'common'})
  const form = useSelector(store => store.form)
  const {formData, formTime, params} = form

  const mobile = useMediaQuery(theme.breakpoints.down("md"))

  const languageTag = i18n.language === 'ru' ? 'ru' : 'en'

  const {banks} = form
  const widgetData = banks?.data?.payment_widget_data ?? {}
  let instructions = widgetData.instructions

  let sourceBanks = banks?.data?.payment_widget_data?.source_banks ?? {}

  const bankData = sourceBanks[form.selectedSourceBank?.key]

  if(bankData && bankData.instructions) {
    instructions = bankData.instructions
  }

  const [toolTipsState, setToolTipsState] = React.useState({
    account_number: {
      isOpen: false,
      timeout: null,
    },
    pan: {
      isOpen: false,
      timeout: null,
    },
    name: {
      isOpen: false,
      timeout: null,
    },
    phone: {
      isOpen: false,
      timeout: null,
    }
  })

  const timeLimit = formData?.data?.ttl * 60 - (((Date.now() - formTime) / 1e3) | 0)
  const [time, setTime] = useState(timeLimit)
  const timeMin = String(Math.floor(Math.round(time) / 60)).padStart(2, '0')
  const timeSec = String(Math.round(time) % 60).padStart(2, '0')

  useEffect(() => {
    if (!timeLimit) {
      return
    }
    const started = Date.now()
    setTime(timeLimit - (Date.now() - started) / 1e3)
    const interval = setInterval(() => {
      setTime(timeLimit - (Date.now() - started) / 1e3)
    }, 1e3)
    return () => clearInterval(interval)
  }, [timeLimit])

  useEffect(() => {
    if (time <= 0) {
      dispatch(formSlice.actions.formTimeout())
    }
  }, [time, mobile])

  const {beneficiary} = formData.data
  const bankInfo = getBankInfo(beneficiary.bank_name)

  let {type: paymentType, displayType, helpTexts, helperTextsMobile, sberPay} = getRequisiteType(beneficiary, params)

  let amountRaw = params.amount
  if (formData?.data?.is_amount_changed) {
    amountRaw = formData?.data?.amount
  }

  // form.selectedSourceBank?.key -> all
  // beneficiary.requisite_type -> default
  const instruction = displayType

  function makeHowToList(instruction) {
    if (instructions) {
      const texts = []
      let index = 0
      while (++index) {
        if (instructions[`${index}`]) {
          texts.push(instructions[`${index}`])
        }
        if (!instructions[`${index}`] && index) {
          break
        }
      }
      return texts
    }
    const texts = []
    let index = 0
    while (++index) {
      const key = `CardForm.HowToJPAY.${instruction}.${index.toString()}`
      const text = t(`HowToJPAY.${instruction}.${index.toString()}`)
      if (key === text) {
        break
      }
      texts.push(text)
    }
    return texts
  }

  const instructionEl = useMemo(() => {
    return (
      <div style={mobile ? {margin: '16px 0'} : {margin: '0 16px'}}>
        <div className={mobile ? styles.textSubtitle : styles.textTitle} style={{marginBottom: 16}}>
          {t(`HowToJPAY.title`)}
        </div>
        <div className={'list'}>
          {makeHowToList(instruction).map((text, index) => (
            <div
              className={`${mobile ? styles.textSmall : styles.textMedium} ${styles.listItem}`}
              key={`key-${index}`}
              data-number={`${index + 1}.`}
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    )
  }, [instruction, mobile])

  const requisiteValue = displayType === 'sbp' ? beneficiary.phone
    : (displayType === 'account' ? beneficiary.account_number : beneficiary.pan)

  return (
    <Layout
      title={ct(`h1.${displayType}`)}
      under={instructionEl}
    >
      {mobile ? (
        <>
          <div className={`${styles.textSubtitle} ${styles.center}`} style={{marginBottom: '16px'}}>
            {ct(`title.${displayType}`)}
          </div>
          <div className={`${styles.textSmall} ${styles.center}`}>
            {ct(`order`, {order: form.params.invoice_id})}
          </div>

          <div style={{margin: '16px auto'}}>
            <Amount
              amount={amountRaw}
              canCopy={true}
            />
          </div>

          <RequisitesCard
            type={displayType}
            bankName={displayType === 'sbp' ?
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
              :
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
            }
            value={requisiteValue}
            cardOwner={beneficiary.name}
          />

          <div style={{margin: '24px auto', display: 'flex'}}>
            <span className={styles.textSmall} style={{marginRight: '6px'}}>
              {t('remainingTime')}
            </span>
            <span className={styles.textSubtitle}>
              {timeMin}:{timeSec}
            </span>
          </div>

          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              ...stylesObject.btnPrimary,
              marginBottom: '12px',
            }}
            onClick={() => dispatch(formSlice.actions.update({status: 'paid'}))}
          >
            {t('paymentConfirmed')}
          </Button>
          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{
              ...stylesObject.btnSecondary,
            }}
            onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
          >
            {t('cancel')}
          </Button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: '32px',
          }}
        >
          <RequisitesCard
            type={displayType}
            bankName={displayType === 'sbp' ?
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
              :
              bankInfo.name ? bankInfo.name : (beneficiary[`bank_name_${languageTag}`] ? beneficiary[`bank_name_${languageTag}`] : beneficiary.bank_name)
            }
            value={requisiteValue}
            cardOwner={beneficiary.name}
          />

          <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <div className={styles.textTitle} style={{marginBottom: '12px'}}>
              {ct(`title.${displayType}`)}
            </div>
            <div className={styles.textMedium} style={{marginBottom: '12px'}}>
              {ct(`order`, {order: form.params.invoice_id})}
            </div>

            <Amount
              amount={amountRaw}
              canCopy={true}
            />

            <div className={styles.textMedium} style={{marginTop: '12px'}}>
              {t('remainingTime')}{' '}{timeMin}:{timeSec}
            </div>

            <div style={{flexGrow: 1}}/>

            <div style={{display: 'flex', gap: '12px'}}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnPrimary,
                }}
                onClick={() => dispatch(formSlice.actions.update({status: 'paid'}))}
              >
                {t('paymentConfirmed')}
              </Button>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  ...stylesObject.btnSecondary,
                }}
                onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
              >
                {t('cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}
